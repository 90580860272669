import { FC, ImgHTMLAttributes } from 'react';

interface NovaProps extends ImgHTMLAttributes<HTMLImageElement> {
  fullBody?: boolean;
}

const Nova: FC<NovaProps> = (props) => {
  const { fullBody, ...rest } = props;
  return (
    <img
      width={100}
      src={fullBody ? "/assets/icons/nova-fullbody.png" : "/assets/icons/nova.png"}
      {...rest}
    />
  );
};

export default Nova;
