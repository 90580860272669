import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { ChatThread, Thread } from 'src/types/chat';
import type SimpleBarCore from 'simplebar-core';

export const useMessagesScroll = (
  thread?: Thread | ChatThread,
  onScrollTop?: () => void
): {
  messagesRef: MutableRefObject<SimpleBarCore | null>;
} => {
  const messagesRef = useRef<SimpleBarCore | null>(null);
  const lastMessage = useRef<string>();
  const handleUpdate = useCallback((): void => {
    // Thread does not exist
    if (!thread) {
      return;
    }

    // Ref is not used
    if (!messagesRef.current) {
      return;
    }

    const container = messagesRef.current;
    const scrollElement = container!.getScrollElement() as HTMLElement;

    if (scrollElement) {
      scrollElement.scrollTop = 100_000_000;
      // Fire onScrollTop when user scrolls at the top
      scrollElement.addEventListener('scroll', () => {
        if (scrollElement.scrollTop === 0 && onScrollTop) {
          onScrollTop();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thread]);

  useEffect(
    () => {
      if (thread?.messages?.[thread?.messages.length - 1]?.body !== lastMessage.current) {
        lastMessage.current = thread?.messages?.[thread?.messages.length - 1]?.body;
        handleUpdate();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [thread?.messages]
  );

  return {
    messagesRef,
  };
};
