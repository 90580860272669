import { ReactNode, type FC } from 'react';
import Stack from '@mui/material/Stack';

import type { Message, Participant } from 'src/types/chat';

import { useSpeaker } from 'src/hooks/use-speech';
import { CHAT_AI_AUTHOR_KEY } from 'src/api/chat/data';
import { ChatMessageAIResponse } from './chat-message-ai-response';
import { type StackProps } from '@mui/system';

interface ChatMessagesAIOnlyProps {
  currentThreadId?: string;
  llmBackgroundActions?: Message[];
  messages?: Message[];
  participants?: Participant[];
  onClickQuestion?: (question: string) => void;
  other?: StackProps;
}

export const ChatMessagesAIOnly: FC<ChatMessagesAIOnlyProps> = (props) => {
  const {
    messages = [],
    participants = [],
    onClickQuestion = () => {},
    currentThreadId,
    llmBackgroundActions,
    ...other
  } = props;
  const speaker = useSpeaker();
  const handleSpeakMessage = (message: string) => {
    speaker.speak(message);
  };

  return (
    <Stack {...other}>
      {messages.map((message, index) => {
        return (
          <ChatMessageAIResponse
            key={message.id}
            authorAvatar={''}
            message={message}
            position={message.authorId === CHAT_AI_AUTHOR_KEY ? 'left' : 'right'}
            onSpeakMessage={handleSpeakMessage}
            userQuery={message}
            isLastMessage={messages.length === index + 1}
            onClickQuestion={onClickQuestion}
            currentThreadId={currentThreadId}
            llmBackgroundActions={llmBackgroundActions}
          />
        );
      })}
    </Stack>
  );
};
