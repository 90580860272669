import { Stack, styled } from '@mui/material';
import { Send } from 'lucide-react';
import { ChangeEventHandler, FC, useEffect, useRef } from 'react';
import { Button } from 'antd';

const TextArea = styled('textarea')`
  border: none;
  padding: 3px 10px;
  width: 100%;
  resize: none;
  box-sizing: border-box;
  outline: none;
  font-size: 14px;
  font-family: inherit;
  line-height: inherit;
  overflow: auto;
  border-radius: 30px;

  &:focus {
    outline: none;
    border: none;
  }
`;

interface InputTextareaProps {
  value: string;
  disabled?: boolean;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  onSend: () => void;
}
const InputTextarea: FC<InputTextareaProps> = (props) => {
  const { value, disabled = false, onChange, onSend } = props;
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current && textareaRef.current.scrollHeight < 280) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <Stack
      direction="row"
      px={1}
      spacing={1}
      alignItems="center"
      sx={{ bgcolor: (theme) => theme.palette.background.default, py: 1 }}
    >
      <TextArea
        disabled={disabled}
        autoFocus
        ref={textareaRef}
        value={value}
        onChange={onChange}
        rows={1}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onSend();
          }
        }}
      />
      <Button
        disabled={!value.trim()}
        type="primary"
        onClick={onSend}
        icon={<Send size={18} />}
        style={{ borderRadius: '100%' }}
      ></Button>
    </Stack>
  );
};

export default InputTextarea;
