import { track } from '@vercel/analytics';

export type TrackEvent =
  // Click for button: Memory > Upload File
  | 'memory-file-upload'
  // Click for button: Memory > Add Live URL
  | 'memory-url-upload'
  // Click to open a new chat as chamber
  | 'chamber-new-chat'
  // Click to open a new chat as member
  | 'member-new-chat'
  // Click to flip a tile
  | 'tile-flipped'
  // Edited business' own profile
  | 'member-edit-their-business'
  // Update business' emails
  | 'chamber-updated-business-email'
  // Send invite to pilot
  | 'invite-to-pilot'
  // Click to Signup button from anonymous chat
  | 'signup-from-chat'
  // Click to download files from chat
  | 'download-content-from-chat'

  // Onboarding Chambers
  | 'chamber-onboarding-memory-upload'
  | 'chamber-onboarding-members-upload'
  | 'chamber-onboarding-set-nova-interaction'

  // Onboarding Members
  | 'member-onboarding-user-role'
  | 'member-onboarding-edited-profile'
  | 'member-onboarding-set-nova-interaction'

  | 'member-trigger-top-competitor-search';

type AllowedProperties = string | number | boolean | null;

export const record = (trackEvent: TrackEvent, extra?: Record<string, AllowedProperties>) => {
  track(trackEvent, extra);
};

export const recordHanldeFlip = (
  type: string,
  isTileFlipped: boolean,
  onFlipTile: (isTileFlipped: boolean) => void
) => {
  if (isTileFlipped) {
    record('tile-flipped', { type });
  }
  onFlipTile(isTileFlipped);
};
