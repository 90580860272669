import { type FC, useCallback, AnchorHTMLAttributes, DetailedHTMLProps } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { LLM_CHAT_LKEYWORDS } from 'src/config';

const LinkRenderer = (
  props: DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
) => {
  return (
    <a
      href={props.href}
      target="_blank"
      rel="noreferrer"
    >
      {props.children}
    </a>
  );
};

export const CustomMarkdown: FC<{ children: string }> = (props) => {
  const { children } = props;
  const filterLLMKeywords = useCallback(() => {
    let str = children;
    for (const keyword of LLM_CHAT_LKEYWORDS) {
      str = str.replaceAll(keyword, '');
    }
    return str;
  }, [children]);

  return (
    <Markdown
      components={{ a: LinkRenderer }}
      remarkPlugins={[remarkGfm]}
    >
      {filterLLMKeywords()}
    </Markdown>
  );
};
