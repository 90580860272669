import { RequestDemand, RequestSuppliers } from 'src/types/request-demand';
import AuthAxios from '../auth-axios';
import { VITE_API_LEVELPLANE } from 'src/config';
import { APIResultListFast } from 'src/types/api';
import { mockRequestSuppliers } from './data';

class RequestsDemandAPI extends AuthAxios {
  constructor() {
    super({ baseUrl: VITE_API_LEVELPLANE });
  }

  async getRequestById(demandId: string): Promise<RequestDemand> {
    try {
      const { data } = await this.axiosInstance.get(`/demand_requests/${demandId}`);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async getRequestSuppliers(demandId: string): Promise<RequestSuppliers> {
    try {
      const { data } = await this.axiosInstance.get(`/demand_requests/${demandId}/suppliers`);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async getRequestCustomers(demandId: string): Promise<RequestSuppliers> {
    try {
      const { data } = await this.axiosInstance.get(`/demand_requests/${demandId}/customers`);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async getRequests(): Promise<APIResultListFast<RequestDemand>> {
    try {
      const { data } = await this.axiosInstance.get(`/demand_requests`);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  }
}

export const requestDemandAPI = new RequestsDemandAPI();
