import { Box, Fade, IconButton } from '@mui/material';
import { Input, message, Typography } from 'antd';
import { Briefcase, Search, ArrowUp, Eye} from 'lucide-react';
import { ChangeEventHandler, KeyboardEventHandler, useState } from 'react';
import ConversationStarters from './conversation-starters';

interface BlankChatProps {
  onSend: (message: string) => void;
}

const conversationKeys: Record<string, string> = {
  chamber_benefits: 'I want to know more about my benefits',
  find_supplier: 'I want to find a supplier',
  track_competitors: 'I want to know about my competitors',
};

const BlankChat = ({ onSend }: BlankChatProps) => {
  const [text, setText] = useState('');

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setText(event.target.value);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter' && text.trim()) {
      onSend(text);
      setText('');
    }
  };
  const handleClickConversationStarter = (conversationKey: string) => {
    if (conversationKey in conversationKeys) {
      onSend(conversationKeys[conversationKey]);
      return;
    }
    message.error('Option not supported. Please report to team');
  };
  return (
    <Fade
      in
      timeout={1000}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography.Title level={3}>How can I help?</Typography.Title>
        <Input
          value={text}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          placeholder="Message Nova"
          style={{ maxWidth: 600, borderRadius: '20px' }}
          suffix={
            <IconButton
              disabled={!text.trim()}
              sx={{
                bgcolor: '#222222',
                ':hover': { opacity: 0.8, bgcolor: '#222222' },
                ':disabled': { opacity: 0.4, bgcolor: '#222222' },
              }}
              onClick={() => {
                if (text.trim()) {
                  onSend(text);
                  setText('');
                }
              }}
            >
              <ArrowUp
                color="white"
                size={18}
              />
            </IconButton>
          }
        />
        <ConversationStarters
          onClickButton={handleClickConversationStarter}
          buttons={[
            {
              key: 'chamber_benefits',
              title: 'Chamber Benefits',
              icon: (
                <Briefcase
                  color="green"
                  size={18}
                />
              ),
            },
            {
              key: 'find_supplier',
              title: 'Find & Connect Suppliers',
              icon: (
                <Search
                  color="orange"
                  size={18}
                />
              ),
            },
            {
              key: 'track_competitors',
              title: 'Track Competitors',
              icon: <Eye size={18} />,
              disabled: true,
            },
          ]}
        />
      </Box>
    </Fade>
  );
};

export default BlankChat;
