import { CHAT_AI_AUTHOR_KEY } from 'src/api/chat/data';
import { omniChat } from 'src/api/omni-chat';
import { AppThunk } from 'src/store';
import { OrchestratorLLMAgent } from 'src/types/chamber-members';
import { ChatResponseType, Message, ChatThread, ChatGet } from 'src/types/chat';
import { User } from 'src/types/user';
import { createResourceId } from 'src/utils/create-resource-id';
import { slice as sliceOrgChat } from 'src/slices/organization-chat';
import { slice as sliceV2MemberChat } from 'src/slices/v2-member-chat';
import { requestDemandAPI } from 'src/api/request-demand';
import { RequestDemand } from 'src/types/request-demand';
import { createMessage } from 'src/utils/chat';
import type { ThunkDispatch } from 'redux-thunk';
import { AIAgent } from 'src/types/smb';

type AddOrganizationMessageParams = {
  authorId: string;
  threadId?: string;
  recipientIds?: string[];
  body: string;
  file?: File;
  responseType: ChatResponseType;
};

// Anonymous chat uss Org Slice
const loadAnonymousOmniChat =
  (
    orchestratorLLMAgent: OrchestratorLLMAgent,
    demandId: string | null,
    signupKey: string | null
  ): AppThunk =>
  async (dispatch): Promise<string> => {
    const title = 'New Thread';
    const body: Record<string, unknown> = {};
    if (demandId) {
      body.chat_context = {
        demand_id: demandId,
      };
    }

    if (signupKey) {
      body.chat_context = {
        signup_key: signupKey,
        ...(body.chat_context as Record<string, unknown>),
      };
    }
    const { chat_id } = await omniChat.postOmniChat(title, orchestratorLLMAgent, body);
    dispatch(
      sliceOrgChat.actions.getThreads([
        {
          messages: [],
          threadTitle: title,
          id: chat_id,
          lastInteractionAt: 0,
        },
      ])
    );
    dispatch(sliceOrgChat.actions.setCurrentThread(chat_id));
    return chat_id;
  };

const loadRequestDemands =
  (user: User): AppThunk =>
  async (dispatch): Promise<void> => {
    const { results } = await requestDemandAPI.getRequests();
    const threads = results?.map(formatRequestToThread);
    const byRequestDemand = results.reduce(
      (acc, next) => {
        acc[next.chat_request_id] = next;
        return acc;
      },
      {} as Record<string, RequestDemand>
    );
    dispatch(sliceV2MemberChat.actions.getThreads({ chats: threads, byRequestDemand }));
  };

const loadChats =
  (user: User, openInitialThread: string | null): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { results } = await omniChat.getOmniChats(user.id);
    const threads = results?.map(formatAPIChatToThread);
    dispatch(sliceV2MemberChat.actions.getThreads({ chats: threads }));
    if (!openInitialThread) {
      dispatch(sliceV2MemberChat.actions.setCurrentThread(undefined));
    } else {
      handleSelectThread(openInitialThread, user, dispatch, getState);
    }
  };
const loadChatBarShortcut =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(sliceV2MemberChat.actions.getThreads({ chats: [] }));
    // dispatch(sliceV2MemberChat.actions.setCurrentThread(undefined));
    createThreadInternal(dispatch, 'member_agent');
  };

const addMessageAuth =
  (params: AddOrganizationMessageParams): AppThunk =>
  (dispatch): void => {
    dispatch(
      sliceV2MemberChat.actions.addMessage({
        message: {
          id: createResourceId(),
          attachments: [],
          contentType: 'text',
          body: params.body,
          authorId: params.authorId,
          createdAt: new Date().getTime(),
          read: false,
        },
        threadId: params.threadId!,
      })
    );
  };

const addMessage =
  (params: AddOrganizationMessageParams): AppThunk =>
  (dispatch): void => {
    dispatch(
      sliceOrgChat.actions.addMessage({
        message: {
          id: createResourceId(),
          attachments: [],
          contentType: 'text',
          body: params.body,
          authorId: params.authorId,
          createdAt: new Date().getTime(),
          read: false,
        },
        threadId: params.threadId!,
      })
    );
  };

type StartingMessage = 'welcome_member' | 'ask_chamber_question';
const createThread =
  (
    orchestratorLLMAgent: OrchestratorLLMAgent,
    allowedUserType?: string,
    signupKey?: string | null,
    startingMessage?: StartingMessage,
    aiAgentWorkflow?: AIAgent,
  ): AppThunk =>
  async (dispatch): Promise<string> => {
    return createThreadInternal(
      dispatch,
      orchestratorLLMAgent,
      allowedUserType,
      signupKey,
      startingMessage,
      aiAgentWorkflow
    );
  };

const emptyChat =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(sliceV2MemberChat.actions.setCurrentThread(undefined));
  };

async function createThreadInternal(
  dispatch: ThunkDispatch<unknown, unknown, any>,
  orchestratorLLMAgent: OrchestratorLLMAgent,
  allowedUserType?: string,
  signupKey?: string | null,
  startingMessage?: StartingMessage,
  aiAgentWorkflow?: AIAgent,
): Promise<string> {
  const body: Record<string, unknown> = {};

  if (allowedUserType) {
    body.allowed_user_type = allowedUserType;
  }

  if (signupKey) {
    body.chat_context = {
      signup_key: signupKey,
      ...(body.chat_context as Record<string, unknown>),
    };
  }

  const response = await omniChat.postOmniChat(undefined, orchestratorLLMAgent, body);
  const messages: Message[] = [];
  if (startingMessage) {
    messages.push(
      createMessage(
        startingMessage,
        response.chat_id,
        'full_response',
        new Date().getTime(),
        [],
        startingMessage === 'welcome_member'
          ? 'welcome_member'
          : startingMessage === 'ask_chamber_question'
          ? 'ask_chamber_question'
          : undefined
      ).message
    );
  }
  const newThread: ChatThread = {
    id: response.chat_id,
    messages,
    threadTitle: 'New Chat',
    unreadCount: 0,
    lastInteractionAt: 0,
    workflow: aiAgentWorkflow,
  };

  const slice =
    orchestratorLLMAgent === 'anonymous_member_agent' ? sliceOrgChat : sliceV2MemberChat;
  dispatch(slice.actions.getThread(newThread));
  dispatch(slice.actions.setCurrentThread(newThread.id));

  return newThread.id as string;
}

const selectThread =
  (threadId: string, user: User | null): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    handleSelectThread(threadId, user, dispatch, getState);
  };

const deleteThread =
  (threadId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    await omniChat.deleteOmniChat(threadId);
    dispatch(sliceV2MemberChat.actions.deleteThread(threadId));
  };

async function handleSelectThread(
  threadId: string,
  user: User | null,
  dispatch: (...p: unknown[]) => any,
  getState: () => any
) {
  const PARTY_MAP = {
    ai: CHAT_AI_AUTHOR_KEY,
    user: user?.id ?? '',
  };

  const { threads } = getState().v2MemberChat;
  if (!threads.byId[threadId]) {
    throw new Error('Thread not found');
  }

  // Load if not in store
  if (threads.byId[threadId].messages.length === 0) {
    const results = await omniChat.getOmniChatMessages(threadId);
    const messages: Message[] = results.results?.reverse()?.map((message) => {
      return {
        id: createResourceId(),
        attachments: [],
        body: message.message,
        contentType: 'text',
        createdAt: Number(`${message.timestamp}000`), // seconds to milliseconds
        authorId: PARTY_MAP[message.party],
        read: false,
        timestamp: message?.timestamp,
        actionable_content: message?.actionable_content,
      };
    });

    dispatch(sliceV2MemberChat.actions.loadThreadMessages({ threadId: threadId, messages }));
  }
  // Change the currentThread
  dispatch(sliceV2MemberChat.actions.setCurrentThread(threadId));
}

function formatRequestToThread(apiRecord: RequestDemand): ChatThread {
  return {
    id: apiRecord.chat_request_id,
    threadTitle: apiRecord.demand,
    messages: [],
    unreadCount: 0,
    lastInteractionAt: 0,
  };
}
function formatAPIChatToThread(apiRecord: ChatGet): ChatThread {
  return {
    id: apiRecord.chat_id,
    threadTitle: apiRecord.chat_title,
    messages: [],
    unreadCount: 0,
    lastInteractionAt: apiRecord.last_interaction_at,
  };
}

export const thunks = {
  loadAnonymousOmniChat,
  createThread,
  addMessage,
  addMessageAuth,
  loadRequestDemands,
  selectThread,
  loadChats,
  deleteThread,
  loadChatBarShortcut,
  emptyChat,
};
