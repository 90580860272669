import Box from '@mui/system/Box';
import DescriptionIcon from '@mui/icons-material/Description';
import { truncate } from 'src/utils/strings';
import { FC } from 'react';
import { bytesToSize } from 'src/utils/bytes-to-size';
import { Attachment } from 'src/types/chat';

interface AttachmentMessageProps {
  attachment: Attachment;
}
export const AttachmentMessage: FC<AttachmentMessageProps> = (props) => {
  const { attachment } = props;
  const { fileName, fileSize } = attachment;
  return (
    <Box
      display="flex"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light' ? 'primary.main' : 'primary.main',
        color: 'primary.contrastText',
        borderRadius: '7px',
        p: 2,
        alignItems: 'center',
        minWidth: '180px',
        maxWidth: '280px',
      }}
    >
      <Box>
        <DescriptionIcon fontSize="large" />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        pl={2}
      >
        <Box
          sx={{
            overflow: 'hidden',
            fontWeight: 'bold',
          }}
        >
          {truncate(fileName, { limit: 30, elipsis: true })}
        </Box>
        <Box>{bytesToSize(fileSize)}</Box>
      </Box>
    </Box>
  );
};
