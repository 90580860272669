import { Box, Button } from '@mui/material';
import { FC, useCallback } from 'react';
import AddIcon from '@mui/icons-material/Add';

interface FollowUpQuestionsProps {
  questionType: 'actionable_response_followup_question' | 'actionable_response_ina_assistance';
  question: string;
  onClickQuestion: (question: string) => void;
}

const FollowUpQuestions: FC<FollowUpQuestionsProps> = (props) => {
  const { questionType, question, onClickQuestion } = props;
  const handleClickQuestion = useCallback(() => {
    onClickQuestion(question);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        borderTop: (theme) => `thin solid ${theme.palette.grey[200]}`,
      }}
    >
      <Button
        onClick={handleClickQuestion}
        fullWidth
        sx={{
          textAlign: 'left',
          display: 'flex',
          justifyContent: 'flex-start',
          color: questionType === 'actionable_response_ina_assistance' ? 'success.dark' : 'inherit',
        }}
      >
        <AddIcon sx={{ mr: 2 }} />
        {question}
      </Button>
    </Box>
  );
};

export default FollowUpQuestions;
