import type { FC, ReactNode } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Message } from 'src/types/chat';
import { CustomMarkdown } from 'src/components/custom-markdown';
import { useEffect, useState } from 'react';
import { AttachmentMessage } from 'src/components/attachment-message';
import { Fade, useMediaQuery, useTheme } from '@mui/material';
import { wait } from 'src/utils/wait';
import { useSelector } from 'src/store';
import ActionFromJSON, { ClosedWorkflowTile } from './action-from-json';
import ChatLoaderSkeleton from 'src/components/chat-loader-skeleton';
import { createResourceId } from 'src/utils/create-resource-id';
import FollowUpQuestions from 'src/components/follow-up-questions';
import ChatDemandStatus from './chat-demand-status';
import ChatMessageMemoryUpdate from './chat-message-memory-update';
import Nova from 'src/components/nova';
import { Card, Timeline, Typography as TypographyAntd } from 'antd';
import { Brain, Lightbulb, Package, TrendingUp } from 'lucide-react';
import Markdown from 'react-markdown';

interface ChatMessageAIResponseProps {
  authorAvatar?: string | null;
  message: Message;
  userQuery?: Message;
  position: 'left' | 'right';
  isLastMessage: boolean;
  currentThreadId?: string;
  llmBackgroundActions?: Message[];
  onSpeakMessage?: (message: string) => void;
  onClickQuestion?: (question: string) => void;
}

export const ChatMessageAIResponse: FC<ChatMessageAIResponseProps> = (props) => {
  const {
    message,
    authorAvatar,
    position,
    onSpeakMessage,
    userQuery,
    isLastMessage,
    currentThreadId: currentThreadIdFromProps,
    llmBackgroundActions: llmBackgroundActionsFromProps,
    onClickQuestion = () => {},
    ...other
  } = props;
  const timeElapsed = message?.timestamp ?? new Date().getTime();
  const ago = formatDistanceToNowStrict(timeElapsed);
  const currentThreadIdFromOrg = useSelector((state) => state.organizationChat.currentThreadId);
  const llmBackgroundActionsFromOrg = useSelector(
    (state) => state.organizationChat.llmBackgroundActions
  );
  const currentThreadId = currentThreadIdFromProps ?? currentThreadIdFromOrg;
  const llmBackgroundActions = llmBackgroundActionsFromProps ?? llmBackgroundActionsFromOrg;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: position === 'left' ? 'flex-start' : 'flex-end',
        color: 'text.primary',
        py: 2,
        minHeight: '5rem',
        px: 2,
      }}
      {...other}
    >
      <Stack
        alignItems="flex-start"
        direction="row"
        spacing={2}
        width={'100%'}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              py: 1,
            }}
          >
            <ChatMessageBodyResponse
              key={message.id}
              position={position}
              currentThreadId={currentThreadId}
              message={message}
              onClickQuestion={onClickQuestion}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: position === 'left' ? 'flex-start' : 'flex-end',
              mt: 1,
              px: 2,
            }}
          >
            {message?.contentType !== 'loading' && (
              <Typography
                color="text.secondary"
                noWrap
                variant="caption"
              >
                {ago === '0 seconds' ? 'a moment ' : ago} ago
              </Typography>
            )}
          </Box>
          <LLMBackgroundTasks
            llmBackgroundActions={llmBackgroundActions}
            isLastMessage={isLastMessage}
          />
        </Box>
      </Stack>
    </Box>
  );
};

interface LLMBackgroundTasksProps {
  isLastMessage: boolean;
  llmBackgroundActions: Message[];
}
const phrases = [
  'Data analysis in progress...',
  'Compiling the response...',
  'Gathering relevant information...',
];
const LLMBackgroundTasks: FC<LLMBackgroundTasksProps> = (props) => {
  const { llmBackgroundActions, isLastMessage } = props;
  const [messages, setMessages] = useState<Message[]>([]);
  const [phraseIndex, setPhraseIndex] = useState<number>(0);

  useEffect(() => {
    if (llmBackgroundActions.length > 0 && isLastMessage && phraseIndex < phrases.length) {
      const timer = setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            body: phrases[phraseIndex],
            id: createResourceId(),
            read: true,
            attachments: [],
            authorId: '',
            contentType: 'llm_background_action',
            createdAt: 0,
          },
        ]);
        setPhraseIndex((prevIndex) => prevIndex + 1);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [llmBackgroundActions, isLastMessage, phraseIndex]);

  useEffect(() => {
    if (llmBackgroundActions.length > 0) {
      setMessages((prevMessages) => [
        ...prevMessages,
        llmBackgroundActions[llmBackgroundActions.length - 1],
      ]);
    }
  }, [llmBackgroundActions]);

  if (llmBackgroundActions.length === 0 || !isLastMessage) {
    return null;
  }

  return (
    <Fade
      in={llmBackgroundActions?.length > 0}
      timeout={1000}
    >
      <div>
        <Stack
          direction="column"
          pl={3}
        >
          <Timeline
            items={[
              { children: 'Thinking...', dot: <Brain />, className: 'override-timeline-head' },
              ...messages.map((m) => ({
                children: (
                  <Fade
                    key={m.id}
                    in={!!m.id}
                    timeout={1000}
                  >
                    <div>{m.body}</div>
                  </Fade>
                ),
              })),
            ]}
          />
        </Stack>
      </div>
    </Fade>
  );
};

interface ChatMessageBodyResponseProps {
  position: 'left' | 'right';
  message: Message;
  currentThreadId: string | undefined;
  onClickQuestion: (question: string) => void;
}

const ChatMessageBodyResponse: FC<ChatMessageBodyResponseProps> = (props) => {
  const { position, message, currentThreadId, onClickQuestion } = props;
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const {
    contentType,
    body,
    read,
    attachments,
    actionable_content,
    timestamp,
    eventType,
    extraBody,
  } = message;
  const [, setElapsedTime] = useState(0);
  const [textMessage, setTextMessage] = useState('');
  const [queue, setQueue] = useState<string[]>([]);
  const [isProcessingQueue, setIsProcessingQueue] = useState(false);

  useEffect(() => {
    if (contentType === 'text') {
      setQueue([...queue, body]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body]);

  useEffect(() => {
    // Get the next element from the queue and process it
    const executeTypeWriting = async () => {
      setIsProcessingQueue(true);
      const nextElmentInQueue = queue[0];
      setQueue((prevQueue) => prevQueue.slice(1));
      const nextTextToType = nextElmentInQueue.replace(textMessage, '');
      let index = 0;
      while (index < nextTextToType.length) {
        await wait(5);
        setTextMessage((textMessage) => {
          return `${textMessage}${nextTextToType[index++] ?? ''}${nextTextToType[index++] ?? ''}`;
        });
      }
      setIsProcessingQueue(false);
    };

    if (queue.length > 0 && !isProcessingQueue) {
      executeTypeWriting();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue, isProcessingQueue]);

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime((timeElapsed) => timeElapsed + 60000);
    }, 60000);
    return () => clearInterval(interval);
  }, []);
  return (
    <Fade
      in={!!message}
      timeout={1000}
    >
      <div>
        {contentType === 'image' && (
          <CardMedia
            onClick={(): void => {}}
            image={body}
            sx={{
              height: 200,
              width: 200,
            }}
          />
        )}
        {(contentType === 'text' || contentType === 'email_question') &&
          (position === 'left' ? (
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="flex-start"
              spacing={2}
              sx={{ textAlign: 'left', marginRight: isLargeScreen ? '80px' : '30px' }}
            >
              <Nova style={{ width: '30px', marginTop: '10px' }} />
              <Stack>
                <TypographyAntd.Text
                  style={{
                    wordBreak: 'break-word',
                    backgroundColor: 'white',
                    borderRadius: '1.5rem',
                    padding: '5px 20px',
                  }}
                >
                  <CustomMarkdown>{currentThreadId && !read ? textMessage : body}</CustomMarkdown>
                </TypographyAntd.Text>
              </Stack>
            </Stack>
          ) : (
            <div
              style={{
                textAlign: 'right',
                display: 'flex',
                justifyContent: 'flex-end',
                marginLeft: isLargeScreen ? '80px' : '30px',
              }}
            >
              <TypographyAntd.Text
                style={{
                  wordBreak: 'break-word',
                  backgroundColor: '#f2f2f2',
                  borderRadius: '1.5rem',
                  padding: '5px 20px',
                }}
              >
                <CustomMarkdown>{currentThreadId && !read ? textMessage : body}</CustomMarkdown>
              </TypographyAntd.Text>
            </div>
          ))}
        {actionable_content && (
          <ActionFromJSON
            currentThreadId={currentThreadId as string}
            timestamp={timestamp as number}
            actionableContent={actionable_content}
            body={body}
          />
        )}
        {contentType === 'loading' && <ChatLoaderSkeleton />}
        {contentType === 'demand_status' && <ChatDemandStatus status={JSON.parse(body)} />}
        {contentType === 'memory_update' && (
          <ChatMessageMemoryUpdate
            body={body}
            memoryUpdae={JSON.parse(extraBody as string)}
          />
        )}
        {contentType === 'attachment' && <AttachmentMessage attachment={attachments[0]} />}
        {contentType === 'follow_up_questions' && (
          <FollowUpQuestions
            questionType={
              eventType as
                | 'actionable_response_followup_question'
                | 'actionable_response_ina_assistance'
            }
            question={body}
            onClickQuestion={onClickQuestion}
          />
        )}
        {contentType === 'ask_chamber_question' && (
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            spacing={2}
            sx={{ textAlign: 'left', marginRight: isLargeScreen ? '80px' : '30px' }}
          >
            <Nova style={{ width: '30px', marginTop: '10px' }} />
            <Stack>
              <TypographyAntd.Text
                style={{
                  wordBreak: 'break-word',
                  backgroundColor: 'white',
                  borderRadius: '1.5rem',
                  padding: '5px 20px',
                }}
              >
                <CustomMarkdown>{`**Nova is here to help!**

What do you need assistance with?

Examples:
- “What are the latest regulation updates in my region?”
- “What benefits does the chamber offer?”

Type your question below and get your answer instantly.`}</CustomMarkdown>
              </TypographyAntd.Text>
            </Stack>
          </Stack>
        )}
        {contentType === 'welcome_member' && (
          <Stack>
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="flex-start"
              spacing={2}
              sx={{ textAlign: 'left' }}
            >
              <Nova style={{ width: '30px', marginTop: '10px' }} />
              <Stack>
                <Typography
                  color="inherit"
                  variant="body1"
                  component="div"
                  sx={{ wordBreak: 'break-word' }}
                >
                  <CustomMarkdown>
                    {`### Hey! I’m Nova, your AI teammate here to make things easier
                    
Here are some things I can help you with:
                    `}
                  </CustomMarkdown>
                </Typography>
              </Stack>
            </Stack>
            <Stack
              pl={4}
              direction="row"
              justifyContent="center"
              spacing={2}
            >
              <NovaFeatureCard
                icon={<Lightbulb size={30} />}
                title="Chamber Knowledge"
                description="All questions you have to your chamber"
              />
              <NovaFeatureCard
                icon={<Package size={30} />}
                title="Find suppliers"
                description="Tell me your requirements and I'll find suitable businesses to fullfill it"
              />
              <NovaFeatureCard
                icon={<TrendingUp size={30} />}
                title="Track Competitors"
                description="Complete your profile and I will identify your direct competitors"
              />
            </Stack>
            <Typography
              pl={4}
              color="inherit"
              variant="body1"
              component="div"
              sx={{ wordBreak: 'break-word' }}
            >
              <CustomMarkdown>How can I help you today?</CustomMarkdown>
            </Typography>
          </Stack>
        )}
      </div>
    </Fade>
  );
};

const NovaFeatureCard = (props: { title: string; description: string; icon: ReactNode }) => {
  const { title, description, icon } = props;
  return (
    <Card
      style={{
        pointerEvents: 'none',
        flex: 1,
        maxWidth: '300px',
        fontWeight: 'bold',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      }}
    >
      {icon}
      <Typography variant="subtitle1">{title}</Typography>
      <Typography
        variant="caption"
        color="grey"
      >
        {description}
      </Typography>
    </Card>
  );
};
