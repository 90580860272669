import { Button } from 'antd';
import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useAuth } from 'src/hooks/use-auth';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';

const LogoutIcon = () => {
  const queryClient = useQueryClient();
  const auth = useAuth();
  const router = useRouter();

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      queryClient.clear();
      await auth.signOut();
      router.push(paths.index);
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong!');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, router]);

  return <Button onClick={handleLogout}>Logout</Button>;
};

export default LogoutIcon;
