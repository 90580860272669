import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LANGUAGE } from 'src/locales/i18n';

const SpeechRecognition =
  (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

export const useRecognitionSpeech = () => {
  const recognition = useRef(new SpeechRecognition());
  const [textFromSpeech, setTextFromSpeech] = useState('');
  const { i18n } = useTranslation();
  const accumulatedResults = useRef('');

  recognition.current.continuous = true;
  recognition.current.lang = i18n.language ?? DEFAULT_LANGUAGE;
  recognition.current.interimResults = false;
  recognition.current.maxAlternatives = 1;

  const clearTextFromSpeech = () => {
    setTextFromSpeech('');
  };

  recognition.current.onresult = function (event: any) {
    for (let i = event.resultIndex; i < event.results.length; ++i) {
      if (event.results[i].isFinal) {
        accumulatedResults.current += event.results[i][0].transcript;
      }
    }
  };
  recognition.current.onend = () => {
    setTextFromSpeech(accumulatedResults.current);
    accumulatedResults.current = '';
  };

  return {
    clearTextFromSpeech,
    textFromSpeech,
    speechRecognition: recognition.current,
  };
};

export const useContinuousRecognitionSpeech = (events?: {
  onSpeechStarted?: () => void;
  onSpeechStoped?: () => void;
}) => {
  const recognition = useRef(new SpeechRecognition());
  const [textFromSpeech, setTextFromSpeech] = useState('');
  const [isAudioListening, setIsAudioListening] = useState(false);
  const { i18n } = useTranslation();

  recognition.current.continuous = true;
  recognition.current.lang = i18n.language ?? DEFAULT_LANGUAGE;
  recognition.current.interimResults = false;
  recognition.current.maxAlternatives = 1;

  const startSpeechRecognition = () => {
    recognition.current.lang = i18n.language ?? DEFAULT_LANGUAGE;
    recognition.current.start();
    events?.onSpeechStarted?.();
  };

  const stopSpeechRecognition = () => {
    recognition.current.stop();
    events?.onSpeechStoped?.();
  };

  const clearTextFromSpeech = () => {
    setTextFromSpeech('');
  };

  const setAudioFalse = () => {
    setIsAudioListening(false);
  };
  recognition.current.onresult = function (event: any) {
    const result = event.results[0][0];
    setTextFromSpeech(result.transcript);
  };

  recognition.current.onaudiostart = function (event: any) {
    setIsAudioListening(true);
  };
  recognition.current.onspeechend = () => {
    setAudioFalse();
    recognition.current.stop();
    events?.onSpeechStoped?.();
  };

  recognition.current.onend = setAudioFalse;
  recognition.current.onerror = setAudioFalse;
  recognition.current.onnomatch = setAudioFalse;
  recognition.current.onaudioend = setAudioFalse;

  return {
    startSpeechRecognition,
    stopSpeechRecognition,
    clearTextFromSpeech,
    textFromSpeech,
    isAudioListening,
  };
};

const useMediaAnimation = (events?: {
  onAnimationStarted?: () => void;
  onAnimationStoped?: () => void;
  onMediaVolumeUpdated?: (volume: number) => void;
}) => {
  const startAnimationEvent = new Event('startAnimation');
  const stopAnimationEvent = new Event('stopAnimation');
  const isAnimationOnRef = useRef(false);
  const analyzerRef = useRef<AnalyserNode>();

  const handleStartAnimation = () => {
    events?.onAnimationStarted?.();
  };

  const handleStopAnimation = () => {
    events?.onAnimationStoped?.();
  };

  const startAnimation = (analyzerNode: AnalyserNode) => {
    isAnimationOnRef.current = true;
    analyzerRef.current = analyzerNode;
    animate();
    window.dispatchEvent(startAnimationEvent);
  };

  const stopAnimation = () => {
    isAnimationOnRef.current = false;
    window.dispatchEvent(stopAnimationEvent);
  };

  useEffect(() => {
    window.addEventListener('startAnimation', handleStartAnimation);
    window.addEventListener('stopAnimation', handleStopAnimation);

    return () => {
      window.removeEventListener('startAnimation', handleStartAnimation);
      window.removeEventListener('stopAnimation', handleStopAnimation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Create an animation frame function to get updates for media being recorded
  const animate = () => {
    if (!isAnimationOnRef.current) {
      return;
    }

    (analyzerRef.current as AnalyserNode).fftSize = 256;
    const bufferLength = (analyzerRef.current as AnalyserNode).frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    (analyzerRef.current as AnalyserNode).getByteFrequencyData(dataArray);

    const volume = dataArray.reduce((a, b) => a + b) / dataArray.length;
    events?.onMediaVolumeUpdated?.(volume);

    requestAnimationFrame(animate); // Request the next frame
  };

  return {
    startAnimation,
    stopAnimation,
  };
};

export const useMediaRecorder = (events?: { onMediaVolumeUpdated: (volume: number) => void }) => {
  const mediaRecorderRef = useRef<MediaRecorder>();
  const analyzerRef = useRef<AnalyserNode>();
  const audioContextRef = useRef<AudioContext>();
  const [isRecording, setIsRecording] = useState(false);

  const { startAnimation, stopAnimation } = useMediaAnimation({
    onMediaVolumeUpdated: (volume) => events?.onMediaVolumeUpdated?.(volume),
  });

  const normalizeVolumeValue = useCallback((volume: number, newMin: number, newMax: number) => {
    const oldMin = 0;
    const oldMax = 255;

    // Scale from 0-255 to newMin-newMax
    return ((volume - oldMin) / (oldMax - oldMin)) * (newMax - newMin) + newMin;
  }, []);

  const createStreamMedia = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
    audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
    analyzerRef.current = audioContextRef.current.createAnalyser();
    const source = audioContextRef.current.createMediaStreamSource(stream);
    source.connect(analyzerRef.current);

    return {
      mediaRecorder: mediaRecorderRef.current,
      analyzerNode: analyzerRef.current,
    };
  };

  const startRecordingMedia = async () => {
    try {
      const { mediaRecorder, analyzerNode } = await createStreamMedia();
      startAnimation(analyzerNode);

      mediaRecorder.start();
      mediaRecorder.onstart = () => {
        setIsRecording(true);
      };
      mediaRecorder.onstop = () => {
        setIsRecording(false);
      };
      mediaRecorder.onerror = () => {
        setIsRecording(false);
      };
      mediaRecorder.ondataavailable = (data) => {};
      mediaRecorder.onresume = (data) => {};
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecordingMedia = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
    }
    stopAnimation();
  };

  return { startRecordingMedia, stopRecordingMedia, normalizeVolumeValue, isRecording };
};

export const useSpeaker = () => {
  const synthRef = useRef(window.speechSynthesis);
  const [isSpeaking, setIsSpeaking] = useState(false);

  const speak = (text: string) => {
    if (synthRef.current.speaking) {
      return;
    }
    const utterThis = new SpeechSynthesisUtterance(text);
    utterThis.onstart = () => {
      setIsSpeaking(true);
    };
    utterThis.onend = () => {
      setIsSpeaking(false);
    };
    utterThis.onerror = () => {
      setIsSpeaking(false);
    };

    utterThis.onerror = function (event) {
      console.error('SpeechSynthesisUtterance.onerror', event);
    };

    utterThis.pitch = 1;
    utterThis.rate = 1;
    synthRef.current.speak(utterThis);
  };

  return { speak, isSpeaking };
};
