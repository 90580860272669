import { Box } from '@mui/material';
import { Typography, Button } from 'antd';
import { MessageCircleMore } from 'lucide-react';
import { FC } from 'react';

interface EmptyChatProps {
  onNewThread: () => void;
}

const EmptyChatChambers: FC<EmptyChatProps> = (props) => {
  const { onNewThread } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: 0.3,
        }}
      >
        <Typography.Title level={3}>Select a Chat</Typography.Title>
        <MessageCircleMore />
      </Box>
      <Button
        type="primary"
        style={{ paddingLeft: '30px', paddingRight: '30px', fontWeight: 'bold' }}
        onClick={onNewThread}
      >
        Or start a new one
      </Button>
    </Box>
  );
};

export default EmptyChatChambers;
