import { useEffect, useState } from 'react';
import ChatBar from './chat-bar';
import { Box, IconButton, Stack } from '@mui/material';
import ChatInterface from 'src/components/chat-interface';
import { History, X } from 'lucide-react';
import { useDispatch, useSelector } from 'src/store';
import { ChatResponseType } from 'src/types/chat';
import { thunks } from 'src/thunks/omni-chat';
import { CHAT_USER_GENERIC_KEY } from 'src/api/chat/data';
import { Button, Divider, Typography } from 'antd';
import { useNavigate } from 'react-router';
import { paths } from 'src/paths';

const ChatShortcut = () => {
  const dispatch = useDispatch();
  const threads = useSelector((state) => state.v2MemberChat.threads);
  const currentThreadId = useSelector((state) => state.v2MemberChat.currentThreadId);
  const llmBackgroundActions = useSelector((state) => state.v2MemberChat.llmBackgroundActions);
  const currentThread = threads.byId[currentThreadId as string];
  const [isChatOpen, setIsChatOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(thunks.loadChatBarShortcut());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseChat = () => {
    setIsChatOpen(false);
  };
  const handleOnSend = (response: string) => {
    if (!isChatOpen) {
      setIsChatOpen(true);
    }
    handleSend(response, 'text');
  };
  const handleChatBarFocused = () => {
    navigate(paths.business.chat);
    // setIsChatOpen(true);
  };
  const handleNavigateChatHistory = () => {
    navigate(paths.business.chat);
  };
  const handleSend = (value: string, responseType?: ChatResponseType, threadId?: string) => {
    dispatch(
      thunks.addMessageAuth({
        authorId: CHAT_USER_GENERIC_KEY,
        body: value,
        responseType: responseType ?? 'text',
        threadId: threadId ?? currentThreadId,
      })
    );
  };
  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          bgcolor: 'rgba(255, 255, 255, 0.9)',
          top: isChatOpen ? 0 : '100%',
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 9999,
          opacity: isChatOpen ? 1 : 0,
          pointerEvents: isChatOpen ? 'auto' : 'none',
          transition: 'top 0.5s ease, opacity 0.5s ease',
          backdropFilter: 'blur(10px)',
          boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
          border: '1px solid rgba(255, 255, 255, 0.3)',
        }}
      >
        <Box
          sx={{
            px: {
              xs: 1,
              sm: 5,
              md: 7,
              lg: 7,
            },
            pb: 10,
            pt: 5,
            height: '100vh',
            overflowY: isChatOpen ? 'auto' : 'hidden',
            zIndex: 9999,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography.Title
              level={4}
              style={{ margin: 0 }}
            >
              Chat with Nova
            </Typography.Title>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <Button
                type="default"
                icon={<History size={18} />}
                onClick={handleNavigateChatHistory}
              >
                History
              </Button>
              <IconButton onClick={handleCloseChat}>
                <X strokeWidth={3} />
              </IconButton>
            </Stack>
          </Stack>
          <Divider />
          <ChatInterface
            sx={{ flex: 1, height: 'calc(100vh - 208px)', maxWidth: '100%' }}
            currentThreadId={currentThreadId as string}
            currentThread={currentThread}
            llmBackgroundActions={llmBackgroundActions}
            onNewChat={console.log}
          />
        </Box>
      </Box>
      <ChatBar
        expandChatBar={isChatOpen}
        onSend={handleOnSend}
        onChatBarFocused={handleChatBarFocused}
        containerProps={{
          sx: {
            position: 'fixed',
            bottom: 30,
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '8px',
            zIndex: 9999,
          },
        }}
      />
    </>
  );
};

export default ChatShortcut;
