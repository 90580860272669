import { ContentType, Message } from 'src/types/chat';
import { createResourceId } from './create-resource-id';
import { CHAT_AI_AUTHOR_KEY } from 'src/api/chat/data';
import { AddMessageResponse } from 'src/api/chat';
import {
  WebsocketActionableContent,
  WebsocketContent,
  WebsocketEventType,
} from 'src/types/websocket';

export const createMessage = (
  body: string,
  threadId: string,
  eventType?: WebsocketEventType,
  timestamp?: number,
  actionableContent?: WebsocketActionableContent[],
  contentType?: ContentType,
) => {
  const message: Message = {
    id: createResourceId(),
    attachments: [],
    body,
    contentType:
      contentType ||
      (eventType === 'actionable_response'
        ? 'json'
        : eventType === 'intermediate_response'
        ? 'llm_background_action'
        : 'text'),
    createdAt: new Date().getTime(),
    authorId: CHAT_AI_AUTHOR_KEY,
    eventType,
    read: false,
    timestamp,
    actionable_content: actionableContent,
  };
  const response: AddMessageResponse = {
    threadId: threadId,
    message,
  };

  return response;
};

const contentTypeMap: Partial<Record<WebsocketEventType, Message['contentType']>> = {
  actionable_response: 'json',
  intermediate_response: 'llm_background_action',
  actionable_response_followup_question: 'follow_up_questions',
  actionable_response_ina_assistance: 'follow_up_questions',
};

export const createMessageFromSocket = (
  body: string,
  threadId: string,
  websocket: WebsocketContent
) => {
  let finalEvent = websocket.event;
  if (websocket.message_type) {
    finalEvent = `${finalEvent}_${websocket.message_type}` as WebsocketEventType;
  }

  const message: Message = {
    id: createResourceId(),
    attachments: [],
    body,
    contentType: contentTypeMap[finalEvent] ?? 'text',
    createdAt: new Date().getTime(),
    authorId: CHAT_AI_AUTHOR_KEY,
    eventType: finalEvent,
    read: false,
    timestamp: websocket.timestamp,
    actionable_content: websocket.actionable_content,
  };
  const response: AddMessageResponse = {
    threadId: threadId,
    message,
  };

  return response;
};
