type TruncateConfig = {
  limit: number;
  elipsis: boolean;
};

export const truncate = (text: string, config: TruncateConfig): string => {
  if (!text) return '';
  return `${text.substring(0, config.limit)}${
    config.elipsis && text.length > config.limit ? '...' : ''
  }`;
};
