import { APIResultListFast } from 'src/types/api';
import { VITE_API_LEVELPLANE } from 'src/config';
import { ChatGet, ChatMessage, ChatPost } from 'src/types/chat';
import AuthAxios from '../auth-axios';
import { type AxiosResponse } from 'axios';
import { OrchestratorLLMAgent } from 'src/types/chamber-members';

interface PostOmniChatBodyRequest {
  allowed_user_type: string;
  chat_context: Record<string, unknown>;
  signup_key: string;
}

class OmniChat extends AuthAxios {
  constructor() {
    super({ baseUrl: VITE_API_LEVELPLANE });
  }

  async getOmniChats(userId: string): Promise<APIResultListFast<ChatGet>> {
    try {
      const { data } = await this.axiosInstance.get('/omni-chats?has_interactions=true', {
        params: {
          user_id: userId,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getOmniChatMessages(threadId: string): Promise<APIResultListFast<ChatMessage>> {
    try {
      const { data } = await this.axiosInstance.get(`/omni-chats/${threadId}/messages`);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async postOmniChat(
    chatTitle: string | undefined,
    orchestratorLLMAgent: OrchestratorLLMAgent,
    bodyRequest?: Partial<PostOmniChatBodyRequest>
  ): Promise<ChatPost> {
    try {
      const body: Partial<PostOmniChatBodyRequest> = {};

      if (bodyRequest?.allowed_user_type) {
        body.allowed_user_type = bodyRequest.allowed_user_type;
      }

      if (bodyRequest?.chat_context) {
        body.chat_context = bodyRequest.chat_context;
      }

      const { data } = await this.axiosInstance.post('/omni-chats', {
        orchestrator_llm_agent: orchestratorLLMAgent,
        ...body,
      });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async deleteOmniChat(chatId: string): Promise<void> {
    try {
      await this.axiosInstance.delete(`/omni-chats/${chatId}`);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async downloadFile(chatId: string, timestamp: number): Promise<AxiosResponse<Blob>> {
    try {
      return await this.axiosInstance.get(
        `/omni-chats/${chatId}/messages/${timestamp}/message_content`,
        {
          headers: {
            Accept: 'application/vnd.ms-excel',
          },
          responseType: 'blob',
        }
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export const omniChat = new OmniChat();
