import { Box, Stack, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { omniChat } from 'src/api/omni-chat';
import { WebsocketActionableContent } from 'src/types/websocket';
import { paths } from 'src/paths';
import { VITE_BASE_UI_URL } from 'src/config';
import { CustomMarkdown } from 'src/components/custom-markdown';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button as AntdButton, Card, Typography as AntdTypography, Button, Progress } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { record } from 'src/utils/analytics';
import { Home, Zap } from 'lucide-react';

interface ActionFromJSONProps {
  currentThreadId: string;
  body?: string;
  timestamp: number;
  actionableContent: WebsocketActionableContent[];
}

const ActionFromJSON: FC<ActionFromJSONProps> = (props) => {
  const { actionableContent, timestamp, body, currentThreadId } = props;
  if (actionableContent?.[0]?.type === 'xls_export_able') {
    return (
      <XSLContent
        currentThreadId={currentThreadId}
        timestamp={timestamp}
      />
    );
  }
  if (actionableContent?.[0]?.type === 'signup_smb_user') {
    return (
      <SignUpLink
        currentThreadId={currentThreadId}
        body={body}
      />
    );
  }
  if (actionableContent?.[0]?.type === 'delegated_action_concluded') {
    return <ClosedWorkflowTile />;
  }

  return null;
};

export const ClosedWorkflowTile = () => {
  const navigate = useNavigate();
  const handleHomeNavigation = () => {
    navigate(paths.business.home);
  };
  return (
    <Box
      pt={2}
      pl={5}
    >
      <Card
        title={
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <Zap
              style={{ color: 'GrayText', fill: 'GrayText' }}
              size={14}
            />
            <AntdTypography.Text>Workflow triggered</AntdTypography.Text>
          </Stack>
        }
        bordered={false}
        style={{ width: 300 }}
      >
        <Stack
          spacing={2}
          alignItems="center"
        >
          <Progress
            type="circle"
            percent={60}
            format={() => (
              <AntdTypography.Text style={{ fontSize: '14px' }}>In Progress</AntdTypography.Text>
            )}
          />
          <AntdTypography.Text style={{ color: 'gray' }}>
            We are working on your request...
          </AntdTypography.Text>
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
          >
            <AntdTypography.Text style={{ color: 'gray', whiteSpace: 'nowrap', fontWeight: 'bold' }}>
              Learn more in
            </AntdTypography.Text>

            <Button
              onClick={handleHomeNavigation}
              icon={<Zap size={18} />}
              type="primary"
              style={{ width: '100%' }}
            >
              Home
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

interface XSLContentProps {
  body?: string;
  timestamp: number;
  currentThreadId: string;
}

const SignUpLink = (props: { body?: string; currentThreadId: string }) => {
  const [searchParams] = useSearchParams();
  const handleClickSignUp = useCallback(() => {
    if (searchParams.get('demand_id')) {
      // This is seller interview
      const smb_email = searchParams.get('smb_email') || '';
      const smb_name = searchParams.get('smb_name') || '';
      const smb_id = searchParams.get('smb_id') || '';
      const org_id = searchParams.get('org_id') || '';
      const demand_id = searchParams.get('demand_id') || '';

      // Construct the query string
      const queryParams = new URLSearchParams({
        email: smb_email,
        smb_name: smb_name,
        smb_id: smb_id,
        org_id: org_id,
        demand_id: demand_id,
      }).toString();

      // Function to handle opening the new URL
      record('signup-from-chat', { type: 'interview' });
      window.open(`${VITE_BASE_UI_URL}${paths.auth.login}?${queryParams}`, '_blank');
    } else {
      // This is chamber's member interview
      const queryParams = new URLSearchParams({
        chat_id: props.currentThreadId || '',
        org_signup_key: searchParams.get('signup_key') || '',
      }).toString();

      record('signup-from-chat', { type: 'member' });
      window.open(`${VITE_BASE_UI_URL}${paths.auth.login}?${queryParams}`, '_blank');
    }
  }, [props.currentThreadId, searchParams]);

  return (
    <Box
      pt={1}
      pl={4}
    >
      {props.body && (
        <Typography
          color="inherit"
          variant="body1"
          component="div"
          sx={{ wordBreak: 'break-word' }}
        >
          <CustomMarkdown>{props.body}</CustomMarkdown>
        </Typography>
      )}
      <Typography
        mb={1}
      >{`Sign Up now to continue your conversation with NOVA, and receive personalized follow-ups to support your journey with us!`}</Typography>
      <AntdButton
        type="primary"
        onClick={handleClickSignUp}
      >
        Click here to sign up
      </AntdButton>
    </Box>
  );
};

const XSLContent: FC<XSLContentProps> = (props) => {
  const { timestamp, currentThreadId } = props;
  const [isDownloading, setDownloading] = useState<boolean>(false);
  const handleDownload = async () => {
    if (!currentThreadId) {
      record('download-content-from-chat', { success: false });
      console.error('No current thread found');
      return;
    }
    try {
      setDownloading(true);
      const response = await omniChat.downloadFile(currentThreadId, timestamp);
      const url: string = window.URL.createObjectURL(new Blob([response.data]));
      const link: HTMLAnchorElement = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'content.xlsx');
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      record('download-content-from-chat', { success: true });
    } catch (error) {
      record('download-content-from-chat', { success: false });
    } finally {
      setDownloading(false);
    }
  };
  return (
    <Box
      pt={1}
      pl={4}
    >
      {props.body && (
        <Typography
          color="inherit"
          variant="body1"
          component="div"
          sx={{ wordBreak: 'break-word' }}
        >
          <CustomMarkdown>{props.body}</CustomMarkdown>
        </Typography>
      )}
      <AntdButton
        type="primary"
        icon={<DownloadOutlined />}
        onClick={handleDownload}
        loading={isDownloading}
      >
        Download File
      </AntdButton>
    </Box>
  );
};

export default ActionFromJSON;
