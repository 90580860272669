import { Stack, type StackProps } from '@mui/material';
import { Button, Tooltip } from 'antd';
import { ReactNode } from 'react';

interface ButtonConfig {
  key: string;
  title: string;
  icon: ReactNode;
  disabled?: boolean;
}

interface ConversationStartersProps {
  buttons: ButtonConfig[];
  onClickButton: (buttonKey: string) => void;
}

const ConversationStarters = ({
  buttons,
  onClickButton,
  ...rest
}: ConversationStartersProps & StackProps) => {
  return (
    <Stack
      mt={2}
      direction="row"
      spacing={1}
      flexWrap="wrap"
      justifyContent="center"
      {...rest}
    >
      {buttons.map((button, index) => (
        <Tooltip
          key={button.key}
          title={button.disabled ? 'Coming soon' : ''}
        >
          <Button
            style={{ borderRadius: '15px', marginTop: '8px' }}
            disabled={button.disabled}
            icon={button.icon}
            onClick={() => onClickButton(button.key)}
          >
            {button.title}
          </Button>
        </Tooltip>
      ))}
    </Stack>
  );
};

export default ConversationStarters;
