import React from 'react';
import { Stack, Skeleton } from '@mui/material';
import { keyframes, styled } from '@mui/system';

interface SkeletonProps {
  finalWidth: string;
  delay: string;
}

const createPulse = (finalWidth: string) => keyframes`
  0% {
    width: 0%;
    visibility: visible;
  }
  100% {
    width: ${finalWidth};
    visibility: visible;
  }
`;

const AnimatedSkeleton = styled(Skeleton, {
  shouldForwardProp: (prop) => prop !== 'finalWidth' && prop !== 'delay',
})<SkeletonProps>(({ finalWidth, delay }) => ({
  visibility: 'hidden',
  animation: `${createPulse(finalWidth)} 1s ease-in-out ${delay} forwards`,
  animationDelay: delay,
}));

const ChatLoaderSkeleton: React.FC = () => {
  const skeletons = [
    { width: '80%', delay: '0s' },
    { width: '90%', delay: '0.3s' },
    { width: '70%', delay: '0.6s' },
  ];

  return (
    <Stack
      pt={2}
      spacing={2}
      width="100%"
    >
      {skeletons.map((skeleton, index) => (
        <AnimatedSkeleton
          key={index}
          variant="text"
          width={skeleton.width}
          finalWidth={skeleton.width}
          delay={skeleton.delay}
        />
      ))}
    </Stack>
  );
};

export default ChatLoaderSkeleton;
