import { FC, useEffect, useMemo, useState } from 'react';
import { Box, Stack, type StackProps } from '@mui/material';
import { Scrollbar } from 'src/components/scrollbar';
import { useMessagesScroll } from 'src/hooks/use-messages-scroll';
import EmptyChatMembers from 'src/sections/dashboard-v2/chat/empty-chat-members';
import { ChatMessagesAIOnly } from 'src/sections/dashboard/chat/chat-messages-ai-only';
import { ChatThread, Message } from 'src/types/chat';
import InputTextarea from './input-textarea';
import BlankChat from './blank-chat';
import { Select, Steps, Typography } from 'antd';
import { Handshake, MessageCircle, Pickaxe, Server, User, Zap } from 'lucide-react';
import { AIAgent } from 'src/types/smb';
import { CHAT_AI_AUTHOR_KEY } from 'src/api/chat/data';
import { useAuth } from 'src/hooks/use-auth';
import EmptyChatChambers from 'src/sections/dashboard-v2/chat/empty-chat-chambers';
import { useDispatch, useSelector } from 'src/store';
import { slice } from 'src/slices/v2-member-chat';

interface AIChatNewProps extends StackProps {
  currentThreadId: string;
  currentThread: ChatThread;
  llmBackgroundActions: Message[] | undefined;
  withConversationStarters?: boolean;
  backgroundLogo?: string;
  isLimitExceeded?: boolean;
  isLimitExceededMessage?: string;
  onNewChat: () => void;
  onSendMessage?: (text: string) => void;
  onNewChatFromAgent?: (agent: AIAgent) => void;
}

const ChatInterface: FC<AIChatNewProps> = (props) => {
  const {
    currentThreadId,
    currentThread,
    llmBackgroundActions,
    withConversationStarters = false,
    backgroundLogo,
    isLimitExceeded,
    isLimitExceededMessage,
    onNewChat,
    onSendMessage,
    onNewChatFromAgent,
    ...rest
  } = props;
  const { messagesRef } = useMessagesScroll(currentThread, console.log);
  const [value, setValue] = useState('');
  const { isWorkflowFinished } = useSelector((state) => state.v2MemberChat.workflow);
  const dispatch = useDispatch();
  const { user } = useAuth();
  const isMember = !!user?.smb_id;

  const handleSend = () => {
    onSendMessage?.(value);
    setValue('');
  };
  const handleSelectWorkflow = (option: AIAgent) => {
    onNewChatFromAgent?.(option);
  };
  const handleClickCard = (option: AIAgent) => {
    onNewChatFromAgent?.(option);
  };
  useEffect(() => {
    dispatch(slice.actions.setWorkflowFinished(currentThread?.closed ?? false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentThread?.closed]);

  return (
    <Stack
      sx={{
        flex: 1,
        height: '100%',
        maxWidth: '100%',
        bgcolor: '#EEE9E1',
        position: 'relative',
        ...(backgroundLogo && {
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${backgroundLogo})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '200px auto',
            filter: 'grayscale(100%)',
            opacity: 0.3,
            zIndex: 0,
          },
        }),
      }}
      {...rest}
    >
      <Box sx={{ overflowY: 'auto', flex: 1, position: 'relative', zIndex: 1 }}>
        {isMember && (
          <WorkflowWrapper
            workflowSelected={currentThread?.workflow ?? null}
            currentThread={currentThread}
            onSelectOption={handleSelectWorkflow}
          />
        )}
        {onSendMessage &&
          currentThreadId &&
          currentThread?.messages?.length === 0 &&
          withConversationStarters && <BlankChat onSend={onSendMessage} />}
        {currentThreadId && (currentThread?.messages?.length > 0 || !withConversationStarters) && (
          <Scrollbar
            ref={messagesRef}
            sx={{ height: '100%', pt: isMember ? '180px' : '0px' }}
          >
            <ChatMessagesAIOnly
              messages={currentThread?.messages || []}
              participants={[]}
              onClickQuestion={console.log}
              currentThreadId={currentThreadId}
              llmBackgroundActions={llmBackgroundActions}
            />
          </Scrollbar>
        )}
        {!currentThreadId && isMember && (
          <EmptyChatMembers
            onClickCard={handleClickCard}
            onNewThread={onNewChat}
          />
        )}
        {!currentThreadId && !isMember && <EmptyChatChambers onNewThread={onNewChat} />}
      </Box>
      {onSendMessage &&
        currentThreadId &&
        (currentThread?.messages?.length > 0 || !withConversationStarters) && (
          <Box
            sx={{
              zIndex: 1,
              borderTop: (theme) => `1px solid ${theme.palette.grey[400]}`,
              '& > :first-of-type': {
                position: 'unset',
              },
            }}
          >
            {isLimitExceeded && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '50px',
                }}
              >
                <Typography.Text style={{ textAlign: 'center', fontWeight: 'bold' }}>
                  {isLimitExceededMessage}
                </Typography.Text>
              </Box>
            )}
            {!isLimitExceeded && (
              <InputTextarea
                value={value}
                disabled={
                  isLimitExceeded ||
                  isWorkflowFinished ||
                  currentThread?.messages?.[currentThread?.messages?.length - 1]
                    ?.actionable_content?.[0]?.type === 'delegated_action_concluded'
                }
                onSend={handleSend}
                onChange={(e) => setValue(e.target.value)}
              />
            )}
          </Box>
        )}
    </Stack>
  );
};

interface WorkflowWrapperProps {
  currentThread: ChatThread;
  workflowSelected: null | AIAgent;
  onSelectOption: (option: AIAgent) => void;
}
const WorkflowWrapper: FC<WorkflowWrapperProps> = ({
  workflowSelected,
  currentThread,
  onSelectOption,
}) => {
  return (
    <Stack
      sx={{
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 9,
        bgcolor: '#EEE9E1',
        width: '100%',
        height: '180px',
      }}
      spacing={3}
      justifyContent="center"
      alignItems="center"
    >
      <WorkflowSelect
        workflowSelected={workflowSelected}
        currentThread={currentThread}
        onSelectOption={onSelectOption}
      />
      <Typography.Title
        level={4}
        style={{
          margin: '12px 0px',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          visibility: currentThread && workflowSelected ? 'visible' : 'hidden',
        }}
      >
        <Zap size={18} />
        {workflowSelected === 'introductions'
          ? 'Request an Introduction'
          : workflowSelected === 'find_supplier'
          ? 'Discover Suppliers'
          : workflowSelected === 'ask_any'
          ? 'Chamber questions'
          : 'Discover Cutomers'}
      </Typography.Title>
      <WorkflowStepper
        workflowSelected={workflowSelected}
        currentThread={currentThread}
      />
    </Stack>
  );
};

interface WorkflowStepperProps {
  workflowSelected: null | AIAgent;
  currentThread: ChatThread;
}

const WorkflowStepper: FC<WorkflowStepperProps> = ({ workflowSelected, currentThread }) => {
  const [isWorkflowDone, setIsWorkflowDone] = useState(false);
  const aiMessagesCount = useMemo(() => {
    return (
      currentThread?.messages?.reduce((current, next) => {
        if (next.authorId === CHAT_AI_AUTHOR_KEY && next.contentType === 'text') {
          current = current + 1;
        }

        return current;
      }, 0) ?? 0
    );
  }, [currentThread?.messages]);
  useEffect(() => {
    setIsWorkflowDone(currentThread?.closed ?? false);
  }, [currentThread?.closed]);

  return (
    <Steps
      style={{
        maxWidth: '420px',
        marginTop: 0,
        visibility:
          currentThread && workflowSelected && workflowSelected !== 'ask_any'
            ? 'visible'
            : 'hidden',
      }}
      current={isWorkflowDone ? 2 : aiMessagesCount < 3 ? 0 : 1}
      progressDot
      items={[
        {
          title:
            workflowSelected === 'introductions' ? (
              <span>
                Business
                <br />
                Name
              </span>
            ) : (
              <span>
                Products or
                <br />
                Service
              </span>
            ),
        },
        {
          title:
            workflowSelected === 'find_customer' ? (
              <span>
                My
                <br />
                Offerings
              </span>
            ) : (
              <span>
                My
                <br />
                Requirements
              </span>
            ),
        },
        {
          title:
            workflowSelected === 'introductions' ? (
              <span>
                Introduction
                <br />
                Requested
              </span>
            ) : workflowSelected === 'find_supplier' ? (
              <span>
                Recommended
                <br />
                Business
              </span>
            ) : (
              <span>
                Recommended
                <br />
                Customer
              </span>
            ),
        },
      ]}
    />
  );
};

interface WorkflowSelectProps {
  workflowSelected: null | AIAgent;
  currentThread: ChatThread;
  onSelectOption: (option: AIAgent) => void;
}

const WorkflowSelect: FC<WorkflowSelectProps> = ({
  workflowSelected,
  currentThread,
  onSelectOption,
}) => {
  return (
    <Select
      value={workflowSelected}
      disabled={currentThread?.messages?.length > 0}
      placeholder={
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent="center"
        >
          <Server
            size={14}
            style={{ color: 'black' }}
          />
          <span style={{ color: 'black' }}>Select a Workflow</span>
        </Stack>
      }
      style={{
        width: 250,
        marginTop: '15px',
      }}
      onChange={onSelectOption}
      options={[
        {
          value: 'introductions',
          label: (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
            >
              <Handshake size={14} />
              <span>Introductions - AI Workflow</span>
            </Stack>
          ),
        },
        {
          value: 'find_supplier',
          label: (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
            >
              <Pickaxe size={14} />
              <span>Find a supplier - AI Workflow</span>
            </Stack>
          ),
        },
        {
          value: 'find_customer',
          label: (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
            >
              <User size={14} />
              <span>Find a customer - AI Workflow</span>
            </Stack>
          ),
        },
        {
          value: 'ask_any',
          label: (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
            >
              <MessageCircle size={14} />
              <span>Ask any questions</span>
            </Stack>
          ),
        },
      ]}
    />
  );
};

export default ChatInterface;
