import { Box, Grid, Stack } from '@mui/material';
import { Typography, Card, Button } from 'antd';
import { MessageCircle, Zap } from 'lucide-react';
import { FC, ReactNode } from 'react';
import { CustomMarkdown } from 'src/components/custom-markdown';
import Nova from 'src/components/nova';
import { useSelector } from 'src/store';
import { AIAgent } from 'src/types/smb';

interface EmptyChatProps {
  onClickCard: (card: AIAgent) => void;
  onNewThread: () => void;
}

const EmptyChatMembers: FC<EmptyChatProps> = (props) => {
  const { onClickCard } = props;
  const mySMB = useSelector((state) => state.smb.mySMB);
  const memberType = mySMB?.smb[0]?.member_type;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        pt: '180px',
      }}
    >
      <Box>
        <Stack>
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            spacing={2}
            sx={{ textAlign: 'left' }}
          >
            <Nova style={{ width: '60px', marginTop: '10px' }} />
            <Stack>
              <Typography.Text style={{ wordBreak: 'break-word' }}>
                <CustomMarkdown>
                  {`### Select one to get started`}
                </CustomMarkdown>
              </Typography.Text>
            </Stack>
          </Stack>
          <Grid
            container
            spacing={2}
            px={1}
            justifyContent="center"
          >
            <Grid
              item
              md={4}
              sm={6}
              xs={12}
            >
              <NovaFeatureCard
                icon={
                  <Zap
                    style={{ color: '#424242', fill: '#424242' }}
                    size={30}
                  />
                }
                title="Find suppliers"
                description="I'll find suitable businesses for you"
                onClick={() => onClickCard('find_supplier')}
              />
            </Grid>

            {memberType !== 'prospect' && (
              <Grid
                item
                md={4}
                sm={6}
                xs={12}
              >
                <NovaFeatureCard
                  icon={
                    <Zap
                      style={{ color: '#424242', fill: '#424242' }}
                      size={30}
                    />
                  }
                  title="Find customers"
                  description="Look for customers within the chamber network"
                  onClick={() => onClickCard('find_customer')}
                />
              </Grid>
            )}

            {memberType !== 'prospect' && (
              <Grid
                item
                md={4}
                sm={6}
                xs={12}
              >
                <NovaFeatureCard
                  icon={
                    <Zap
                      style={{ color: '#424242', fill: '#424242' }}
                      size={30}
                    />
                  }
                  title="Introductions"
                  description="Let me connect you with the right businesses"
                  onClick={() => onClickCard('introductions')}
                />
              </Grid>
            )}

            <Grid
              item
              md={4}
              sm={6}
              xs={12}
            >
              <NovaFeatureCard
                icon={<MessageCircle size={30} />}
                title={memberType === 'prospect' ? 'Questions to AI Teammate' : 'Questions to Chamber'}
                description={memberType === 'prospect' ? 'Ask me anything' : 'Ask me anything about your chamber of commerce'}
                onClick={() => onClickCard('ask_any')}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Box>
  );
};

const NovaFeatureCard = (props: {
  title: string;
  description: string;
  icon: ReactNode;
  onClick: () => void;
}) => {
  const { title, description, icon, onClick } = props;
  return (
    <Card
      style={{
        flex: 1,
        maxWidth: '300px',
        fontWeight: 'bold',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        height: '170px',
        userSelect: 'none',
      }}
    >
      {icon}
      <br />
      <Button
        type="default"
        style={{ margin: '10px 0px' }}
        onClick={onClick}
      >
        {title}
      </Button>
      <br />
      <Typography.Text style={{ color: 'grey' }}>{description}</Typography.Text>
    </Card>
  );
};

export default EmptyChatMembers;
