import { Box, Fade, Grid, Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { CustomMarkdown } from 'src/components/custom-markdown';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';

export interface MemoryUpdate {
  memory_type: string;
  title: string;
  description: string;
}

interface ChatMessageMemoryUpdateProps {
  body: string;
  memoryUpdae: MemoryUpdate;
}

const ChatMessageMemoryUpdate: FC<ChatMessageMemoryUpdateProps> = (props) => {
  const { body, memoryUpdae } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, []);

  return (
    <>
      {show && (
        <Fade
          in={show}
          timeout={1000}
        >
          <Box
            sx={{
              bgcolor: (theme) => theme.palette.grey[100],
              border: (theme) => `1px solid ${theme.palette.grey[300]}`,
              p: 1,
              borderRadius: 1,
              mt: 2,
            }}
          >
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                xs={12}
              >
                <Stack
                  direction="row"
                  alignItems="flex-end"
                  spacing={1}
                >
                  <TipsAndUpdatesOutlinedIcon />
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                  >
                    {memoryUpdae.title}
                  </Typography>
                </Stack>
                <Typography
                  variant="caption"
                  color="gray"
                >
                  {memoryUpdae.description}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      )}
      <Stack
        direction="row"
        alignItems="center"
        pt={2}
      >
        <img
          src="/assets/logo-only-black.png"
          width={36}
          height={36}
          alt="LevelPlane AI avatar"
        />
        <Typography
          ml={1}
          variant="h6"
        >
          Response
        </Typography>
      </Stack>
      <Typography
        color="inherit"
        variant="body1"
        component="div"
        sx={{ wordBreak: 'break-word' }}
      >
        <CustomMarkdown>{body}</CustomMarkdown>
      </Typography>
    </>
  );
};

export default ChatMessageMemoryUpdate;
