import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { FC, ReactNode } from 'react';
import { Stack } from '@mui/material';

import { PanelRightClose, Settings, Home } from 'lucide-react';
import { Tooltip } from '@lobehub/ui';
import { useLocation, useNavigate } from 'react-router';
import { paths } from 'src/paths';
import { useDrawerActions, useDrawerState } from 'src/contexts/drawer/hooks';
import LogoutIcon from './logout-icon';
import { BusinessAppBar, BusinessDrawer, BusinessMain } from 'src/layouts/common/styled-components';
import { useSelector } from 'src/store';
import FeedbackButton from 'src/layouts/dashboard/horizontal-layout/feedback-button';
import ChatShortcut from './chat-shortcut';
import { Button, Typography } from 'antd';

interface MiniDrawerProps {
  children: ReactNode;
}

const MiniDrawer: FC<MiniDrawerProps> = (props) => {
  const { children } = props;
  const mySMB = useSelector((state) => state.smb.mySMB);
  const { setDrawerOpen } = useDrawerActions();
  const { open, drawerWidth, drawerComponent } = useDrawerState();

  const navigate = useNavigate();
  const location = useLocation();
  const handleDrawerToggle = () => {
    setDrawerOpen(!open);
  };

  const handleNavigation = (url: string) => {
    navigate(url);
  };
  const handleHomeNavigate = () => {
    navigate(paths.business.home);
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{ flex: 1, maxWidth: '100%' }}
      >
        <Box sx={{ display: 'flex', position: 'relative', flex: 1, maxWidth: '100%' }}>
          {drawerWidth > 0 && (
            <BusinessDrawer
              open={open}
              drawerWidth={drawerWidth}
            >
              <div style={{ width: drawerWidth, overflowX: 'hidden' }}>{drawerComponent}</div>
            </BusinessDrawer>
          )}
          <BusinessAppBar
            position="fixed"
            open={open}
            drawerWidth={drawerWidth}
            sx={{ right: 'unset', bgcolor: 'white', color: '#000' }}
          >
            <Toolbar>
              {drawerWidth > 0 && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  edge="start"
                  sx={{ mr: 2 }}
                >
                  <PanelRightClose
                    style={{
                      transform: `rotate(${open ? 180 : 0}deg)`,
                      transition: 'transform 0.3s ease-in-out',
                    }}
                  />
                </IconButton>
              )}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                flex={1}
              >
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                >
                  <Typography.Title level={4}>{mySMB?.smb?.[0]?.org_name}</Typography.Title>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                >
                  <Button
                    type="default"
                    icon={<Home size={16} />}
                    onClick={handleHomeNavigate}
                  >
                    Home
                  </Button>
                  <FeedbackButton />
                  <IconButton
                    sx={{
                      marginBottom: '1rem',
                      bgcolor: (theme) =>
                        location.pathname === paths.business.settings
                          ? theme.palette.grey[300]
                          : 'inherit',
                    }}
                    onClick={() => handleNavigation(paths.business.settings)}
                  >
                    <Tooltip
                      title="Settings"
                      arrow
                      zIndex={9999}
                      placement="bottom"
                    >
                      <Settings />
                    </Tooltip>
                  </IconButton>
                  <LogoutIcon />
                </Stack>
              </Stack>
            </Toolbar>
          </BusinessAppBar>
          <BusinessMain
            open={open}
            drawerWidth={drawerWidth}
          >
            {children}
            {location.pathname !== paths.business.chat &&
              location.pathname !== paths.business.settings && <ChatShortcut />}
          </BusinessMain>
        </Box>
      </Stack>
    </>
  );
};

export default MiniDrawer;
