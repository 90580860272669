import { Box, IconButton, styled } from '@mui/material';
import { Button, Input, Tooltip } from 'antd';
import { ArrowUp } from 'lucide-react';
import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from 'react';
import Nova from 'src/components/nova';

const ChatBarContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isExpanded', // Prevent 'isExpanded' from being passed to the DOM
})<{ isExpanded: boolean }>(({ theme, isExpanded }) => ({
  width: isExpanded ? '100%' : '280px', // Expand width on focus
  maxWidth: '600px',
  backgroundColor: 'rgba(24, 144, 255, 0.3)', // Blue color with 50% transparency
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: '30px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Example of a more prominent custom shadow
  display: 'flex',
  gap: '3px',
  transition: 'width 0.5s ease-in-out, transform 0.3s ease-in-out', // Ensure transition works
}));

const FadeButton = styled(Button)(({ theme }) => ({
  opacity: 0,
  transition: 'opacity 0.4s ease-in-out', // Smooth fade-in and fade-out effect
  '&.visible': {
    opacity: 1,
  },
  '&.hidden': {
    opacity: 0,
  },
}));

interface ChatBarProps {
  expandChatBar: boolean;
  containerProps?: Partial<React.ComponentProps<typeof ChatBarContainer>>;
  onSend: (text: string) => void;
  onChatBarFocused: () => void;
}

const ChatBar: FC<ChatBarProps> = (props) => {
  const { expandChatBar, containerProps, onSend, onChatBarFocused } = props;
  const [text, setText] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
    onChatBarFocused();
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && text.trim()) {
      handleOnSend();
    }
  };

  const handleOnSend = () => {
    if (!text.trim()) {
      return;
    }
    onSend(text);
    setText('');
  };
  useEffect(() => {
    if (isFocused) {
      setIsExpanded(true);
    } else if (text.trim()) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [isFocused, text]);

  return (
    <ChatBarContainer
      isExpanded={isExpanded || expandChatBar}
      {...containerProps}
    >
      <IconButton sx={{ p: 0 }}>
        <Nova width={30} />
      </IconButton>
      <Input
        placeholder="Start a request"
        value={text}
        onChange={handleOnChange}
        onKeyPress={handleKeyPress}
        onFocus={handleFocus}
        onBlur={handleBlur}
        styles={{ affixWrapper: { paddingRight: '5px' } }}
        style={{
          flex: 1,
          borderRadius: '30px',
          marginRight: '10px',
          borderColor: 'white',
        }}
        suffix={
          <FadeButton
            disabled={!text.trim()}
            onClick={handleOnSend}
            className={isExpanded ? 'visible' : 'hidden'}
            style={{
              width: '25px',
              height: '25px',
              padding: 0,
              borderRadius: '10px',
              margin: 0,
            }}
            type="primary"
          >
            <ArrowUp
              size={16}
              style={{ cursor: 'pointer' }}
            />
          </FadeButton>
        }
      />
    </ChatBarContainer>
  );
};

export default ChatBar;
