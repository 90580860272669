import { FC, Fragment } from 'react';
import Tooltip, { type TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Avatar } from '@lobehub/ui';
import { Typography, Divider, Button, Spin, Progress } from 'antd';
import { styled } from '@mui/material/styles';
import { Box, List, ListItem, Stack } from '@mui/material';
import { X, Inbox, CheckCheck, Brain, Building, UserCheck } from 'lucide-react';
import { useNotifications } from './hooks';
import { LoadingOutlined } from '@ant-design/icons';
import { Notification, NotificationEnrichmentExtra } from 'src/types/notifications';
import { timestampUnixToHumanDateTime } from 'src/utils/date-locale';
import { useNavigate } from 'react-router';
import { paths } from 'src/paths';
import { useAuth } from 'src/hooks/use-auth';
import { useDispatch } from 'src/store';
import { thunks } from 'src/thunks/notifications';

const { Title, Text } = Typography;
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  zIndex: 9999,
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.grey[300]}`,
    minWidth: '400px',
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
  },
}));

interface NotificationsProps {
  isLoading: boolean;
  data: Notification[];
  enrichmentNotification?: Notification | null;
}
const Notifications: FC<NotificationsProps> = (props) => {
  const { isLoading, data, enrichmentNotification } = props;
  const dispatch = useDispatch();
  const handleDismiss = (notificationId: string) => {
    dispatch(thunks.dismissNotification(notificationId));
  };

  if (isLoading) {
    return (
      <Box
        p={2}
        gap={1}
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Spin
          indicator={<LoadingOutlined spin />}
          size="default"
        />
        <Text style={{ color: 'gray' }}>Loading notifications</Text>
      </Box>
    );
  }

  if (!isLoading && data?.length === 0 && !enrichmentNotification) {
    return (
      <Box
        p={2}
        gap={1}
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Text
          type="secondary"
          style={{ textAlign: 'center' }}
        >{`You don't have notifications at the moment`}</Text>
        <Inbox style={{ color: 'gray' }} />
      </Box>
    );
  }

  return (
    <List>
      {enrichmentNotification && <NotificationItem notification={enrichmentNotification} />}
      {data
        ?.filter((notification) => notification.status !== 'dismissed')
        ?.map((notification, index) => {
          return (
            <Fragment key={`div-${notification.notification_id}`}>
              {(index > 0 || enrichmentNotification) && <Divider />}
              <NotificationItem
                notification={notification}
                onDismiss={handleDismiss}
              />
            </Fragment>
          );
        })}
    </List>
  );
};

interface AIAvatarProps {
  enrichmentNotification?: Notification | null;
}

const AIAvatar: FC<AIAvatarProps> = (props) => {
  const { enrichmentNotification } = props;
  const { isLoading, notifications } = useNotifications();

  return (
    <>
      <HtmlTooltip
        placement="bottom"
        title={
          <Notifications
            isLoading={isLoading}
            data={notifications}
            enrichmentNotification={enrichmentNotification}
          />
        }
      >
        <Avatar
          size={48}
          animation={true}
          avatar="/assets/icons/nova.png"
          shape="circle"
        />
      </HtmlTooltip>
    </>
  );
};

const NotificationItem = (props: {
  notification: Notification;
  onDismiss?: (notificationId: string) => void;
}) => {
  const { notification, onDismiss } = props;
  const navigate = useNavigate();
  const { user } = useAuth();
  const handleChatNavigation = () => {
    const path = user?.smb_id ? paths.business.chat : paths.workspace.chat;
    navigate(`${path}?chat_id=${notification.chat_id}`);
  };
  const handleBusinessListNavigation = () => {
    navigate(paths.workspace.memory.members);
  };
  const handleDismiss = () => {
    console.log(notification);
    console.log(notification.notification_id);
    onDismiss?.(notification.notification_id);
  };
  return (
    <ListItem sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
      <Box>
        <Avatar
          animation={false}
          avatar={
            notification.notification_type === 'enrichment_progress' ? (
              <UserCheck />
            ) : notification.status === 'new' ? (
              <Inbox />
            ) : (
              <CheckCheck />
            )
          }
          background={notification.status === 'new' ? '#d4d4d4' : '#d4d4d4'}
          shape="circle"
        />
      </Box>
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
        >
          <Title
            level={5}
            style={{ marginTop: 0 }}
          >
            {notification.title}
          </Title>
          {notification.notification_type !== 'enrichment_progress' && (
            <X
              onClick={handleDismiss}
              size={20}
              style={{ cursor: 'pointer', color: 'GrayText' }}
            />
          )}
        </Stack>
        <Text type="secondary">{notification.text}</Text>
        {notification.notification_type === 'enrichment_progress' && (
          <Progress percent={(notification.extra as NotificationEnrichmentExtra).progress} />
        )}
        <Text style={{ fontSize: '12px', color: 'gray', fontWeight: 'bold', marginTop: '5 px' }}>
          {timestampUnixToHumanDateTime(notification.timestamp)}
        </Text>
        {notification.notification_type === 'new_chat_message' && (
          <Stack
            pt={1}
            direction="row"
            spacing={2}
            justifyContent="flex-end"
          >
            <Button
              icon={<Brain size={18} />}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
              type="dashed"
              onClick={handleChatNavigation}
            >
              Chat with Nova
            </Button>
          </Stack>
        )}
        {notification.notification_type === 'enrichment_complete' && (
          <Stack
            pt={1}
            direction="row"
            spacing={2}
            justifyContent="flex-end"
          >
            <Button
              icon={<Building size={18} />}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
              type="dashed"
              onClick={handleBusinessListNavigation}
            >
              See my businesses
            </Button>
          </Stack>
        )}
      </Stack>
    </ListItem>
  );
};

export default AIAvatar;
