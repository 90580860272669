import { Box, Button, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import { paths } from 'src/paths';

export interface DemandStatus {
  title: string;
  subtitle: string;
  description: string;
  requirements: string;
}

interface ChatDemandStatusProps {
  status: DemandStatus;
}

const ChatDemandStatus: FC<ChatDemandStatusProps> = (props) => {
  const { status } = props;
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.grey[100],
        border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        p: 3,
        borderRadius: 1,
        mt: 2,
      }}
    >
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          xs={12}
        >
          <Typography variant="h5">{status.title}</Typography>
          <Typography
            variant="subtitle2"
            color="gray"
          >
            {status.subtitle}
          </Typography>
        </Grid>
        <Grid
          item
          xs={7}
        >
          <Typography
            variant="h6"
            mt={1}
          >
            Overview
          </Typography>
          <Typography variant="body2">{status.description}</Typography>
        </Grid>
        <Grid
          item
          xs={5}
        >
          <Typography
            variant="h6"
            mt={1}
          >
            Requirements
          </Typography>
          <Typography variant="body2">{status.requirements}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Button
            sx={{ textDecoration: 'underline' }}
            size="small"
            onClick={() => navigate(paths.dashboard.v2Members.supplierOfferStatus)}
          >
            See more
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChatDemandStatus;
